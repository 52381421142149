<template>
    <button @click.stop="handleClick" :disabled="disabled">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
              :class="{'d-none': !disabled}"></span>
        <span class="ml-2" :class="{'d-none': !disabled}">In Progress...</span>
        <span :class="{'d-none': disabled}"><slot></slot></span>
    </button>
</template>

<script>

export default {
    name: 'AsyncButton',
    data() {
        return {
            disabled: false,
        };
    },
    props: {
        task: {
            type: Function,
            required: true,
        },
    },
    methods: {
        async handleClick() {
            if (this.task && typeof this.task === 'function') {
                this.disabled = true;
                try {
                    await this.task();
                } catch (e) {
                    console.error(e);
                } finally {
                    this.disabled = false;
                }
            }
        },
    }
};
</script>

<style scoped>
.spinner-border {
    vertical-align: -0.125em;
}
</style>