function ticketStateColorLookup(ticket) {
    if (ticket.startsWith('closed_')) {
        return 'secondary';
    }
    if (ticket.startsWith('pending_')) {
        return 'warning';
    }
    if (ticket.startsWith('waiting_')) {
        return 'primary';
    }
    return 'danger';
}

function ticketStateIconLookup(ticket) {
    if (ticket.startsWith('closed_')) {
        return 'check';
    }
    if (ticket.startsWith('pending_')) {
        return 'exclamation';
    }
    if (ticket.startsWith('waiting_')) {
        return 'hourglass';
    }
    return 'exclamation';
}

const http = {
    get: async (url, token) => {
        if (!token) {
            return null;
        }
        const response = await fetch('/api' + url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        const success = response.status === 200 || response.status === 201;
        return {data: await response.json() || {}, success};
    },
    post: async (url, data, token) => {
        if (!token) {
            return null;
        }
        const response = await fetch('/api' + url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify(data),
        });
        const success = response.status === 200 || response.status === 201;
        return {data: await response.json() || {}, success};
    },
    put: async (url, data, token) => {
        if (!token) {
            return null;
        }
        const response = await fetch('/api' + url, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify(data),
        });
        const success = response.status === 200 || response.status === 201;
        return {data: await response.json() || {}, success};
    },
    patch: async (url, data, token) => {
        if (!token) {
            return null;
        }
        const response = await fetch('/api' + url, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
            body: JSON.stringify(data),
        });
        const success = response.status === 200 || response.status === 201;
        return {data: await response.json() || {}, success};
    },
    delete: async (url, token) => {
        if (!token) {
            return null;
        }
        const response = await fetch('/api' + url, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        });
        const success = response.status === 204;
        return {data: await response.text() || {}, success};
    }
}

const http_session = token => ({
    get: async (url) => await http.get(url, token),
    post: async (url, data) => await http.post(url, data, token),
    put: async (url, data) => await http.put(url, data, token),
    patch: async (url, data) => await http.patch(url, data, token),
    delete: async (url) => await http.delete(url, token),
});

export {ticketStateColorLookup, ticketStateIconLookup, http, http_session};