<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
        <div class="dropdown">
            <button class="btn text-light dropdown-toggle btn-heading" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ getEventSlug }}
            </button>
            <div class="dropdown-menu bg-dark" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item text-light" href="#" v-for="(event, index) in selectableEvents"
                   v-bind:key="index"
                   :class="{ active: event.slug === getEventSlug }" @click="changeEvent(event)">{{ event.slug }}</a>
            </div>
        </div>
        <ul class="nav nav-tabs flex-nowrap">
            <li class="nav-item" v-if="checkPermission(getEventSlug, 'view_item')">
                <router-link :to="{name: 'items', params: {event: getEventSlug}}"
                             :class="['nav-link', { active: isItemView() }]">
                    Items
                </router-link>
            </li>
            <li class="nav-item" v-if="checkPermission(getEventSlug, 'view_issuethread')">
                <router-link :to="{name: 'tickets', params: {event: getEventSlug}}"
                             :class="['nav-link', { active: isTicketView() }]">
                    Tickets
                </router-link>
            </li>
            <li class="nav-item" v-if="checkPermission(getEventSlug, 'delete_event')">
                <router-link :to="{name: 'admin'}" class="nav-link" active-class="active">
                    Admin
                </router-link>
            </li>
        </ul>
        <SearchBox v-if="hasPermissions" class="mt-1 my-lg-auto my-xl-auto w-100 d-inline mr-1"/>
        <div class="custom-control-inline mr-1" v-if="hasPermissions">
            <div class="btn-group btn-group-toggle mr-1" v-if="isItemView()">
                <button :class="['btn', 'btn-info', { active: layout === 'cards' }]" @click="setLayout('cards')">
                    <font-awesome-icon icon="th"/>
                </button>
                <button :class="['btn', 'btn-info', { active: layout === 'table' }]" @click="setLayout('table')">
                    <font-awesome-icon icon="list"/>
                </button>
            </div>
            <div class="btn-group btn-group-toggle mr-1" v-if="isTicketView()">
                <button :class="['btn', 'btn-info', { active: layout === 'tasks' }]" @click="setLayout('tasks')">
                    <font-awesome-icon icon="tasks"/>
                </button>
                <button :class="['btn', 'btn-info', { active: layout === 'table' }]" @click="setLayout('table')">
                    <font-awesome-icon icon="list"/>
                </button>
            </div>
            <button type="button" class="btn text-nowrap btn-success mr-1" @click="$emit('addItemClicked')"
                    v-if="isItemView()  && getEventSlug !== 'all'">
                <font-awesome-icon icon="plus"/>
                <span class="d-none d-md-inline">&nbsp;Add Item</span>
            </button>
            <button type="button" class="btn text-nowrap btn-success mr-1" @click="$emit('addTicketClicked')"
                    v-if="isTicketView() && getEventSlug !== 'all'">
                <font-awesome-icon icon="plus"/>
                <span class="d-none d-md-inline">&nbsp;Add Ticket</span>
            </button>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item" v-for="(link, index) in links" v-bind:key="index">
                    <a class="nav-link text-nowrap" :href="link.path" @click.prevent="navigateTo(link.path)">
                        {{ link.title }}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-nowrap" href="/logout" @click.prevent="logout()">
                        Logout
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex';
import SearchBox from "@/components/inputs/SearchBox.vue";

export default {
    name: 'Navbar',
    components: {
        SearchBox
    },
    data: () => ({
        views: [
            {'title': 'items', 'path': 'items'},
            {'title': 'boxes', 'path': 'boxes'},
            //{'title':'mass-edit','path':'massedit'},
        ],
        links: [
            {'title': 'howto engel', 'path': '/howto/'},
        ]
    }),
    emits: ['addItemClicked', 'addTicketClicked'],
    computed: {
        ...mapState(['events']),
        ...mapGetters(['getEventSlug', 'getActiveView', "checkPermission", "hasPermissions", "layout", "route"]),
        selectableEvents() {
            return [{slug: 'all'}, ...this.events, {slug: 'none'}];
        }
    },
    methods: {
        ...mapActions(['changeEvent', 'changeView']),
        ...mapMutations(['logout']),
        navigateTo(link) {
            if (this.route.path !== link)
                this.$router.push(link);
        },
        isItemView() {
            return this.getActiveView === 'items' || this.getActiveView === 'item';
        },
        isTicketView() {
            return this.getActiveView === 'tickets' || this.getActiveView === 'ticket';
        },
        setLayout(layout) {
            if (this.route.query.layout === layout)
                return;
            this.$router.push({...this.route, query: {...this.route.query, layout}});
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../scss/navbar.scss";

.nav-tabs {
    margin-bottom: -0.5rem !important;
    border-bottom: var(--gray) solid 1px !important;

    & .nav-item {
        margin-right: 0.5em;

        &:first-child {
            margin-left: 0.5em;
        }
    }

    & .nav-link {
        padding-bottom: 1rem !important;
        border: var(--gray) solid 1px !important;
        border-bottom: none !important;
        color: var(--blue) !important;

        &.active {
            background: black !important;
            border-bottom: none;
            color: white !important;
        }
    }
}
</style>